<template>
  <main class="row my-4 mx-0">
    <div class="col-xl-12">

      <iframe id="frame" ref="frame" :src="$url_admin"  allowfullscreen  width="100%" height="810px" style="border:none!important;"></iframe>
    </div>

  </main>
</template>

<script>
export default {
  name: "Admin"
}
</script>

<style scoped>

</style>